<template>
  <div>
    <textarea :id="name" :name="name"></textarea>
  </div>
</template>

<script>
import $ from "jquery";
import "bootstrap";
import "summernote/dist/summernote";
import { merge } from "lodash/fp";

export default {
  name: "SummerNote",
  props: {
    model: {
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    height: {
      required: false,
      default: "100px",
    },
    name: {
      type: String,
      required: true,
    },
    forceUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
    config: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      defaultConfig: {
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "italic", "underline", "clear"]],
          // ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
          ["fontname", ["fontname"]],
          ["fontsize", ["fontsize"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["height", ["height"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "hr"]],
          ["view", ["codeview"]],
        ],
      },
    };
  },
  watch: {
    model(newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.forceUpdate) {
        $("#" + this.name).summernote("code", newVal);
        this.$emit("forceUpdate", newVal);
      }
    },
  },
  methods: {},
  mounted() {
    let vm = this;
    let config = merge(this.defaultConfig, this.config);
    config.height = this.height;
    config.callbacks = {
      onInit: function() {
        $("#" + vm.name).summernote("code", vm.model);
      },
      onChange: function() {
        vm.$emit("change", $("#" + vm.name).summernote("code"));
      },
      onBlur: function() {
        vm.$emit("change", $("#" + vm.name).summernote("code"));
      },
    };
    setTimeout(() => {
      $("#" + vm.name).summernote(config);
    }, 100);
  },
};
</script>
<style scoped>
@import "~summernote/dist/summernote.css";
</style>
<style>
.note-editor.note-frame.panel
  button.note-btn.btn.btn-default.btn-sm.dropdown-toggle::after {
  content: unset !important;
}
.note-editor.note-frame.panel
  div.btn-group.note-color-all
  > button.note-btn.btn.btn-default.btn-sm.dropdown-toggle {
  padding: 0px !important;
  padding-left: 5px;
}
</style>
